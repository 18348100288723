import Tree from "./Tree";
import arrowDown from "./assets/arrow-down.svg";

function Invitation() {
  return (
    <div className="invitation">
      <div className="bonny text-center">
        <div className="white font-32">
          Dear Bonny,
          <br />
          You're invited
        </div>
        <img className="top-10" src={arrowDown} height={16} />
      </div>
      <Tree />
    </div>
  );
}

export default Invitation;
