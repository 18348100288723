import logo from "./logo.svg";
import "./styles/app.scss";
import { useEffect } from "react";
import Tree from "./Tree";

function Snow({ children }) {
  useEffect(() => {
    //make snow
    snowDrop(150, randomInt(50, window.innerWidth - 50));
    snow(150, 150);

    function snow(num, speed) {
      if (num > 0) {
        setTimeout(function () {
          document
            .getElementById("drop_" + randomInt(1, 250))
            ?.classList.add("animate");
          num--;
          snow(num, speed);
        }, speed);
      }
    }

    function snowDrop(num, position) {
      if (num > 0) {
        const node = document.createElement("div");
        node.id = "drop_" + num;
        node.className = "drop snow";
        document.getElementById("snow-container").appendChild(node);
        node.style.left = position + "px";
        num--;
        snowDrop(num, randomInt(50, window.innerWidth - 50));
      }
    }

    function randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }, []);
  return <div id="snow-container" />;
}

export default Snow;
