import "./styles/main.scss";
import "./styles/app.scss";
import Snow from "./Snow";
import Invitation from "./Invitation";
import Info from "./Info";
import ReactPlayer from "react-player";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.addEventListener("click", musicPlay);
    function musicPlay() {
      console.log("music play");
      document.getElementById("player").play();
      document.removeEventListener("click", musicPlay);
    }
  }, []);
  return (
    <div id="app">
      <audio id="player" autoPlay loop>
        <source src="/xmas.mp4" type="audio/mp3" />
      </audio>
      <Snow />
      <div id="tree-container">
        <Invitation />
      </div>
      <Info />
    </div>
  );
}

export default App;
