import "./styles/app.scss";
import us from "./assets/us.jpeg";

function Info() {
  return (
    <div className="info">
      <div className="blank" />
      <div className="content">
        <img className="us" src={us} alt="" />
        <div className="white font-24 top-20">🎅🏻 Merry Sungsmas Party</div>
        <div className="white font-18">🗓&nbsp;&nbsp;December 24th, 2021</div>
        <div className="white font-18 top-40 underline">Schedule</div>
        <div className="white font-18 top-20">3:00 Coffee</div>
        <div className="white font-18 top-5">4:00 - 5:00 Special Event</div>
        <div className="white font-18 top-5">6:00 Dinner with Champagne</div>
        <div className="white font-18 top-40 underline">To bring</div>
        <div className="white font-18 top-20">
          A lovely heart with xmas spirit 🎄
        </div>
        <div className="white font-14 top-20">
          Invitation by your one and only hubby - Sung Woo
        </div>
      </div>
    </div>
  );
}

export default Info;
